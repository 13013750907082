/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";
@import "swiper/scss/zoom";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

@import "../node_modules/angular-calendar/css/angular-calendar.css";

.modal-informativa::part(content) {
  --height: 70%;
  --width: 90%;
  --border-radius: 20px;
}

/* .custom-alert-popover::part(content){
    --width: 70%;
} */

.alert-radio-label {
  white-space: normal !important;
}

.espacio-form-buttom {
  margin-top: 30px;
}

.badge,
.badge-default {
  color: #fff;
  background-color: var(--ion-color-primary);
}

.badge-visita,
.badge-preparacion,
.badge-planificacion {
  color: #fff;
  background-color: #7d88ff;
}

.badge-fecha_propuesta {
  color: #fff;
  background-color: #c4c4c4;
}

.badge-instalacion,
.badge-fecha_instalacion {
  color: #fff;
  background-color: #2fd36f;
}
.badge-instalacion_confirmada {
  color: #fff;
  background-color: #017d6e;
}
.badge-ausencias {
  color: #fff;
  background-color: #ff9f43;
}
.badge-incidencia {
  color: #fff;
  background-color: #f07383;
}

.color-visita,
.color-preparacion,
.color-planificacion {
  color: #7d88ff;
}

.color-fecha_propuesta {
  color: #c4c4c4;
}

.color-instalacion,
.color-fecha_instalacion {
  color: #2fd36f;
}
.color-instalacion_confirmada {
  color: #017d6e;
}
.color-incidencia {
  color: #f07383;
}

.icono-color-visita,
.icono-color-preparacion,
.icono-color-planificacion {
  border-color: #7d88ff !important;
  .icono {
    background-color: #7d88ff !important;
  }
}

.icono-color-preparacion,
.icono-color-planificacion {
  border-color: #c4c4c4 !important;
  .icono {
    background-color: #c4c4c4 !important;
  }
}

.icono-color-instalacion {
  border-color: #2fd36f !important;
  .icono {
    background-color: #2fd36f !important;
  }
}
.icono-color-incidencia {
  border-color: #f07383 !important;
  .icono {
    background-color: #f07383 !important;
  }
}

.icono-color-ausencias {
  border-color: #ff9f43 !important;
  .icono {
    background-color: #ff9f43 !important;
  }
}

.icono-tipo-cliente{
    border: 1px solid;
    border-radius: 200px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: #C4C4C4;
    .icono{
        background-color: #C4C4C4;
        width: 26px;
        height: 26px;
    }
    .tipo-bateria{
        mask: url(/assets/img/tipos/bateria.svg) no-repeat center / contain;
        -webkit-mask: url(/assets/img/tipos/bateria.svg) no-repeat center / contain;
    }
    .tipo-instalacion{
        mask: url(/assets/img/tipos/instalacion.svg) no-repeat center / contain;
        -webkit-mask: url(/assets/img/tipos/instalacion.svg) no-repeat center / contain;
    }
    .tipo-ampliacion-paneles{
      mask: url(/assets/img/tipos/ampliacion_paneles.svg) no-repeat center / contain;
      -webkit-mask: url(/assets/img/tipos/ampliacion_paneles.svg) no-repeat center / contain;
    }
    .tipo-ampliacion{
      mask: url(/assets/img/tipos/ampliacion.svg) no-repeat center / contain;
      -webkit-mask: url(/assets/img/tipos/ampliacion.svg) no-repeat center / contain;
      mask-size: 100%; 
      -webkit-mask-size: 100%;
    }
    .tipo-coche{
        mask: url(/assets/img/tipos/coche.svg) no-repeat center / contain;
        -webkit-mask: url(/assets/img/tipos/coche.svg) no-repeat center / contain;
    }
    .tipo-aerotermia{
        mask: url(/assets/img/tipos/aerotermia.svg) no-repeat center / contain;
        -webkit-mask: url(/assets/img/tipos/aerotermia.svg) no-repeat center / contain;
    }
    .tipo-aislada{
      mask: url(/assets/img/tipos/aislada.svg) no-repeat center / contain;
      -webkit-mask: url(/assets/img/tipos/aislada.svg) no-repeat center / contain;
  }
}

.selection {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
}

ion-item-options {
  ion-item-option {
    background: rgba(0, 0, 0, 0.05);
    ion-icon {
      color: black;
      padding: 8px;
      background: rgba(255, 255, 255, 0.8);
      box-shadow: 0px 1.5px 8px rgb(0 0 0 / 25%);
      border-radius: 70px;
      font-size: 20px;
    }
  }
}

.ion-modal-datetime-time {
  --width: 290px;
  --height: 440px;
  --border-radius: 8px;
}

.ion-modal-datetime{
    --width: 290px;
    --height: 430px;
    --border-radius: 8px;
}

.cursor-pointer {
  cursor: pointer;
}

.bold{
    font-weight: bold;
}

ion-item{
    --highlight-background: #b0b0b0;
}

ion-app {
  background-color: white;
}

@media (min-width:320px)  { /* smartphones, iPhone, portrait 480x320 phones */ }
@media (min-width:481px)  { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ }
@media (min-width:641px)  { /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
    ion-app{
        width: 750px;
        margin: auto;
        border: 1px solid #ededed;
    }
  }



cdk-virtual-scroll-viewport {
  height: 100%;
  width: 100%;
  padding-bottom: 100px !important;
}


swiper-container {
  --swiper-pagination-bullet-inactive-color: var(--ion-color-step-200, #cccccc);
  --swiper-pagination-color: var(--ion-color-primary, #3880ff);
  --swiper-pagination-progressbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.25);
  --swiper-scrollbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.1);
  --swiper-scrollbar-drag-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.5);
}

swiper-slide {
  display: flex;
  position: relative;

  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  font-size: 18px;

  text-align: center;
  box-sizing: border-box;
}

swiper-slide img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

ion-select::part(container){
  width: 100%;
}

.btn-form-light{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #222428;
    background: rgba(255, 255, 255, 0.8);
    border: 2px solid #D7D7D7;
    border-radius: 13px;
    margin: 10px auto;
    min-height: 56px;
    text-transform: capitalize;
}

ion-button {
  &.boton-sombreado {
    border-radius: 13px;
    color: #000;
    font-weight: bold;
    background-color: #fff;
    box-shadow: 0px 1px 4px 0px #00000040;

    &.boton-secundario {
      background: #000;
      color: #fff;
    }
  }

  &.boton-redondo {
    border-radius: 50%;
    width: 46px;
    height: 46px;
    min-height: 0;
    --padding-start: 0.5rem;
    --padding-end: 0.5rem;
  }
}

.select-popover{
  --width: auto !important;
}