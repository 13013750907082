// Ionic Variables and Theming. Para más info, ver:
// http://ionicframework.com/docs/theming

/** ================================
    Modo Claro (por defecto)
    ================================ */
    :root {
      /** primary (amarillo claro) **/
      --ion-color-primary: #F7A932;
      --ion-color-primary-rgb: 240, 199, 61;
      --ion-color-primary-contrast: #000000;
      --ion-color-primary-contrast-rgb: 0, 0, 0;
      --ion-color-primary-shade: #E5B635; /* Opcional */
      --ion-color-primary-tint: #F6D65C;  /* Opcional */
    
      /** secondary (por ejemplo un gris claro) **/
      --ion-color-secondary: #92949c;
      --ion-color-secondary-rgb: 146, 148, 156;
      --ion-color-secondary-contrast: #ffffff;
      --ion-color-secondary-contrast-rgb: 255, 255, 255;
      --ion-color-secondary-shade: #808289;
      --ion-color-secondary-tint: #9d9fa6;
    
      /** danger (rojo claro) **/
      --ion-color-danger: #eb445a;
      --ion-color-danger-rgb: 235, 68, 90;
      --ion-color-danger-contrast: #ffffff;
      --ion-color-danger-contrast-rgb: 255, 255, 255;
      --ion-color-danger-shade: #cf3c4f;
      --ion-color-danger-tint: #ed576b;
    
      /** dark **/
      --ion-color-dark: #222428;
      --ion-color-dark-rgb: 34, 36, 40;
      --ion-color-dark-contrast: #ffffff;
      --ion-color-dark-contrast-rgb: 255, 255, 255;
      --ion-color-dark-shade: #1e2023;
      --ion-color-dark-tint: #383a3e;
    
      /** medium **/
      --ion-color-medium: #92949c;
      --ion-color-medium-rgb: 146, 148, 156;
      --ion-color-medium-contrast: #ffffff;
      --ion-color-medium-contrast-rgb: 255, 255, 255;
      --ion-color-medium-shade: #808289;
      --ion-color-medium-tint: #9d9fa6;
    
      /** light **/
      --ion-color-light: #f4f5f8;
      --ion-color-light-rgb: 244, 245, 248;
      --ion-color-light-contrast: #000000;
      --ion-color-light-contrast-rgb: 0, 0, 0;
      --ion-color-light-shade: #d7d8da;
      --ion-color-light-tint: #f5f6f9;
    
      /* Ejemplo de colores extra para gráficas, etc. (modo claro) */
      --color-energia-consumida: #f8c063;
      --color-energia-autoconsumida: #8798ff;
      --color-energia-generada: #fff699;
      --color-energia-exportada: #86ecbc;
      --color-energia-importada: #dd7975;
      --color-energia-carga-bateria: #97239b;
      --color-energia-descarga-bateria: #d576d9;
      --color-estado-normal: #10b5ab;
      --color-estado-alerta: #f09873;
      --color-estado-incidencia: #f07383;
      --color-horas-mediodia: #ffe395;
      --color-horas-tarde: #ffb595;
      --color-horas-manana: #fff2cf;
      --color-huella-verde-actual: #79c591;
      --color-huella-verde-pasada: #d4d4d4;
      --color-leads-semana-actual: #f8c063;
      --color-leads-semana-pasada: #d4d4d4;
      --color-autoconsumo: #f8c063;
      --titulo-color: #000000; 
    }
    
    /** ========================================================
        MODO OSCURO al usar la clase .dark en el <body>
        ======================================================== */
    body.dark {
      /* Fondo gris oscuro */
      --ion-background-color: #2C2C2C;
      --ion-background-color-rgb: 47, 47, 47;
    
      /* Texto principal en blanco sobre fondo gris */
      --ion-text-color: #ffffff;
      --ion-text-color-rgb: 255, 255, 255;
    
      /* Color principal (amarillo) con texto en negro */
      --ion-color-primary: #F7A932;
      --ion-color-primary-rgb: 240, 199, 61;
      --ion-color-primary-contrast: #000000;
      --ion-color-primary-contrast-rgb: 0, 0, 0;
      --ion-color-primary-shade: #E5B635;
      --ion-color-primary-tint: #F6D65C;
    
      /* Danger en rojo #F32A2A con texto blanco */
      --ion-color-danger: #F32A2A;
      --ion-color-danger-rgb: 243, 42, 42;
      --ion-color-danger-contrast: #ffffff;
      --ion-color-danger-contrast-rgb: 255, 255, 255;
    
      /* Secondary o medium en gris medio con texto blanco */
      --ion-color-medium: #4C4C4C;
      --ion-color-medium-rgb: 76, 76, 76;
      --ion-color-medium-contrast: #ffffff;
      --ion-color-medium-contrast-rgb: 255, 255, 255;
    
      /* Si prefieres "secondary" sea gris */
      --ion-color-secondary: #4C4C4C;
      --ion-color-secondary-contrast: #ffffff;
    
      /* Dark/Light override */
      --ion-color-dark: #2C2C2C;
      --ion-color-dark-rgb: 47, 47, 47;
      --ion-color-dark-contrast: #ffffff;
      --ion-color-light: #f4f5f8;
      --ion-color-light-contrast: #000000;
    
      /* Ajusta aquí colores extra en modo oscuro si los necesitas */
      --color-energia-consumida: #bea240;
      --color-energia-autoconsumida: #6770ff;
      --color-energia-generada: #ffef7f;
      --color-energia-exportada: #66d9a4;
      --titulo-color: #ffffff; 
    }
    
    /* iOS: sobrescribe colores si el modo es iOS y body tiene la clase .dark */
    .ios body.dark {
      --ion-background-color: #2C2C2C;
      --ion-background-color-rgb: 47,47,47;
    
      /* Matices de gris (iOS “steps”) */
      --ion-color-step-50: #3A3A3A;
      --ion-color-step-100: #3A3A3A;
      --ion-color-step-150: #404040;
      --ion-color-step-200: #444444;
      --ion-color-step-250: #4A4A4A;
      --ion-color-step-300: #4F4F4F;
      --ion-color-step-350: #555555;
      --ion-color-step-400: #5A5A5A;
      --ion-color-step-450: #606060;
      --ion-color-step-500: #666666;
      --ion-color-step-550: #6B6B6B;
      --ion-color-step-600: #707070;
      --ion-color-step-650: #767676;
      --ion-color-step-700: #7B7B7B;
      --ion-color-step-750: #818181;
      --ion-color-step-800: #878787;
      --ion-color-step-850: #8D8D8D;
      --ion-color-step-900: #939393;
      --ion-color-step-950: #999999;
    
      --ion-item-background: #2F2F2F;
      --ion-card-background: #4C4C4C;
    }
    
    .ios body.dark ion-modal {
      --ion-background-color: var(--ion-color-step-100);
      --ion-toolbar-background: var(--ion-color-step-150);
      --ion-toolbar-border-color: var(--ion-color-step-250);
    }
    
    /* Material Design (Android): sobrescribe colores si el modo es .md y body.dark */
    .md body.dark {
      --ion-background-color: #2F2F2F;
      --ion-background-color-rgb: 47,47,47;
      --ion-border-color: #444444;
    
      --ion-color-step-50: #3A3A3A;
      --ion-color-step-100: #3A3A3A;
      --ion-color-step-150: #404040;
      --ion-color-step-200: #444444;
      --ion-color-step-250: #4A4A4A;
      --ion-color-step-300: #4F4F4F;
      --ion-color-step-350: #555555;
      --ion-color-step-400: #5A5A5A;
      --ion-color-step-450: #606060;
      --ion-color-step-500: #666666;
      --ion-color-step-550: #6B6B6B;
      --ion-color-step-600: #707070;
      --ion-color-step-650: #767676;
      --ion-color-step-700: #7B7B7B;
      --ion-color-step-750: #818181;
      --ion-color-step-800: #878787;
      --ion-color-step-850: #8D8D8D;
      --ion-color-step-900: #939393;
      --ion-color-step-950: #999999;
    
      --ion-item-background: #2F2F2F;
      --ion-toolbar-background: #4C4C4C;
      --ion-tab-bar-background: #4C4C4C;
      --ion-card-background: #3A3A3A;
    }
    
    /* Mantén la configuración de fuente dinámica */
    html {
      --ion-dynamic-font: var(--ion-default-dynamic-font);
    }
    